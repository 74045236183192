import React, { createContext, useReducer } from 'react';
import { FormThemeMode, User } from './__generated__/graphql';
import client from './apollo';
import api from './common/api';
import {
  REFRESH_TOKEN,
  ROUTES,
  TOKEN,
  USER,
  USER_WORKSPACE_ID,
} from './common/constants';
import {
  AppAction,
  AppActionType,
  AppContextType,
  AppState,
} from './types/appContext.type';
import { ChildrenPropType } from './types/common.type';

const getLoggedInUser = (): User | null => {
  const loggedInUser = localStorage.getItem(USER);
  return loggedInUser ? JSON.parse(loggedInUser) : null;
};

const initialState: AppState = {
  currentUser: getLoggedInUser() || {},
  authenticated: !!localStorage.getItem(TOKEN),
  authToken: localStorage.getItem(TOKEN),
  redirectRoute: ROUTES.MAIN,
  // adding common loader state for app
  appState: {
    formCreateLoading: false,
    formFetchLoading: false,
    formCreateError: false,
  },
  themeList: [],
  activeThemeId: '',
  activeThemeVariationId: '',
  activeMode: FormThemeMode.Auto,
};

const reducer = (state: AppState, action: AppAction) => {
  switch (action?.type) {
    case AppActionType.setCurrentUser:
      // eslint-disable-next-line no-case-declarations
      const user = action.data || {};
      localStorage.setItem(
        USER_WORKSPACE_ID,
        user?.defaultWorkspace?.uuid || '',
      );
      return { ...state, currentUser: { ...user } };
    case AppActionType.setCurrentRole:
      return { ...state, currentRole: action.data };
    case AppActionType.setRedirectRoute:
      return { ...state, redirectRoute: action.data };
    case AppActionType.setSystemThemeMode:
      return { ...state, systemThemeMode: action.data };
    case AppActionType.logout:
      delete api?.defaults?.headers?.common?.Authorization;
      localStorage.clear();
      client?.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        user: {},
      };
    case AppActionType.setAuthenticated:
      return { ...state, authenticated: action.data };
    case AppActionType.setAppThemes:
      return { ...state, themeList: action.data || [] };
    case AppActionType.setActiveThemeIds:
      return { ...state, ...action?.data };
    case AppActionType.setFormSettings:
      // eslint-disable-next-line no-case-declarations
      const formSettings = action.data || {};
      return {
        ...state,
        formSettings: { ...state?.formSettings, ...formSettings },
      };
    case AppActionType.setAuthToken:
      localStorage.setItem(TOKEN, action.data ?? '');
      return { ...state, authToken: action.data };
    case AppActionType.setAppState:
      // eslint-disable-next-line no-case-declarations
      const data = action.data || {};
      return {
        ...state,
        appState: { ...state?.appState, ...data },
      };
    case AppActionType.setRefreshToken:
      localStorage.setItem(REFRESH_TOKEN, action.data ?? '');
      return {
        ...state,
        refreshToken: action.data,
      };
    default:
      return { ...state };
  }
};

const AppContext = createContext<AppContextType | null>(null);

const AppContextProvider: React.FC<ChildrenPropType> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () => localStorage.getItem(TOKEN) || null;
  const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);
  const getCurrentUser = () =>
    localStorage?.getItem(USER)
      ? JSON?.parse(localStorage.getItem(USER) ?? '')
      : {};

  const isAuthenticated = () => state.authenticated;

  const initializeAuth = (
    authToken: string | null,
    userData: User | null,
    refreshToken?: string | null,
    route?: string,
  ) => {
    const token = authToken || getToken();
    const user = userData || getCurrentUser();
    const refresh = refreshToken || getRefreshToken();
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch({ type: AppActionType.setAuthToken, data: token });
      dispatch({ type: AppActionType.setRefreshToken, data: refresh });
      dispatch({ type: AppActionType.setAuthenticated, data: true });
      dispatch({ type: AppActionType.setCurrentUser, data: user });
      dispatch({ type: AppActionType.setRedirectRoute, data: route });
    }
  };

  const value: AppContextType = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    getRefreshToken,
    initializeAuth,
    getCurrentUser,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextConsumer, AppContextProvider };
