import { useLazyQuery } from '@apollo/client';
import {
  CalendarBlank,
  CaretDown,
  Clock,
  Microphone,
} from '@phosphor-icons/react';
import { Button, ConfigProvider, Flex, Form, Space, UploadProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Dayjs } from 'dayjs';
import { isEmpty, map } from 'lodash';
import { useEffect, useState } from 'react';
import { FormThemeMode } from '../../../__generated__/graphql';
import { DROPDOWN_LIST, getSliderMarks } from '../../../common/constants';
import {
  commonUploadFile,
  formValidation,
  getAcceptedFileTypes,
  getDisabledDates,
  validateFileSize,
} from '../../../common/utils';
import useQueryParams from '../../../hooks/useQueryParams';
import { FormFieldType } from '../../auth/auth.types';
import MadeWithZinq from '../../branding/MadeWithZinq';
import {
  CustomFile,
  FormSchemaType,
  InfoType,
  Palette,
} from '../../form-builder/model/form/form.types';
import { FormFieldConversational } from '../FormFieldConversational';
import PagePreview from '../editor/pages/PagePreview';
import { GET_SIGNED_URL_UPLOAD } from '../graphql/mutations';

type ConversationalUIRenderType = {
  item: FormSchemaType;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  onFinish: (data: any, currentKey?: string) => Promise<boolean>; // using any for dynamic values
  activeTheme: Palette;
  handleCTAClick: () => void;
  apiLoader?: boolean;
  isUpload: boolean;
  showFooterButtons: boolean;
  themeMode: FormThemeMode;
  autoFocus: boolean;
  branding: boolean;
};

export default function ConversationalUIPreview({
  item,
  onFinish,
  activeTheme,
  handleCTAClick,
  apiLoader,
  isUpload = false,
  showFooterButtons,
  themeMode,
  autoFocus,
  branding,
}: ConversationalUIRenderType) {
  const [form] = useForm();
  const [apiErrors] = useState<Record<string, string>>({});
  const [fileList, setFileList] = useState<CustomFile[]>([]);
  const [optionList, setOptionList] = useState<string[]>([]);
  const [fileCount, setFileCount] = useState<number>(0);
  const [imageUploading, setImageUploading] = useState<boolean>(false);
  const { getQueryParam } = useQueryParams();
  const formKey = getQueryParam('formKey') as string;
  const formPreviewKey = getQueryParam('formPreviewKey') as string;

  const [getSignedUrlMutate, { loading }] = useLazyQuery(
    GET_SIGNED_URL_UPLOAD,
    {
      fetchPolicy: 'network-only',

      onError: () => {},
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnFinish = async (formValue: any) => {
    await onFinish(formValue);
    setFileList([]);
    setOptionList([]);
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
  }, [item]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpload = async ({ file, onSuccess, onError }: any) => {
    try {
      if (!validateFileSize(file, Number(item?.config?.max_file_size))) {
        onError(
          new Error(
            `You can upload file size upto ${item?.config?.max_file_size}MB`,
          ),
        );
        return true;
      }

      const response = await getSignedUrlMutate({
        variables: {
          data: {
            files: [
              {
                fileName: file.name,
                contentLength: file.size,
              },
            ],
            questionKey: item?.answer_store_key as string,
            submissionId: localStorage.getItem(formPreviewKey || formKey) || '',
          },
        },
      });

      const signedUrl =
        response.data?.getSubmissionFilesUploadSignedUrl?.signedUrls?.[0]
          ?.signedUrl;
      const key =
        response?.data?.getSubmissionFilesUploadSignedUrl?.signedUrls?.[0]?.key;

      if (signedUrl && key) {
        try {
          setImageUploading(true);
          const success = await commonUploadFile(signedUrl, file);
          if (success) {
            onSuccess(response, file, response);
          } else {
            onError(new Error('Error uploading file!'));
          }
        } catch (e) {
          onError(new Error('Error uploading file!'));
        } finally {
          setImageUploading(false);
        }
      } else {
        onError(new Error('Failed to get signed URL!'));
      }
    } catch (error) {
      onError(error);
    }
  };

  const uploadProps: UploadProps = {
    accept: getAcceptedFileTypes(item?.config?.file_type),
    name: '',
    multiple: Number(item?.config?.max_file_count) > 1,
    fileList,
    customRequest: (info) => {
      if (isUpload) {
        handleUpload(info);
      } else {
        if (info.onSuccess) {
          info?.onSuccess('');
        }
      }
    },
    onRemove: () => {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange(info: any) {
      let newFileList = [...info.fileList];
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url =
            file.response?.data?.getSubmissionFilesUploadSignedUrl?.signedUrls?.[0]?.key;
        }
        return file;
      });
      setFileList(newFileList);
      form.setFieldValue(
        'userResponse',
        map(newFileList, (file) => file?.url),
      );
    },
  };

  const FooterButtons = ({
    info,
    isSubmit = false,
    btnText = 'Next',
    isSkip = false,
    isDisabled = false,
  }: {
    info: InfoType;
    btnText?: string;
    isSubmit?: boolean;
    isSkip?: boolean;
    isDisabled?: boolean;
  }) => {
    return (
      <Flex justify="space-between">
        <div>
          {!item?.is_required && isSkip && (
            <Button
              type="default"
              size="large"
              shape="round"
              disabled={loading || apiLoader || imageUploading}
              onClick={() => {
                // form.resetFields();
                form?.submit();
              }}
            >
              Skip
            </Button>
          )}
        </div>
        <div>
          {isSubmit && (
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              shape="round"
              loading={loading || apiLoader}
              disabled={isDisabled}
            >
              {btnText}
            </Button>
          )}
        </div>
      </Flex>
    );
  };

  const renderItemUI = (item: FormSchemaType, info: InfoType) => {
    const commonRules = item?.is_required ? [formValidation.required] : [];
    switch (item.answer_type) {
      case FormFieldType.CONTACT_INFO:
      case FormFieldType.ADDRESS:
      case FormFieldType.SHORT_TEXT:
        return (
          <FormFieldConversational
            name={info?.name}
            label={info?.label}
            type={FormFieldType.SHORT_TEXT}
            apiErrors={apiErrors}
            rules={[...commonRules, formValidation.whitespace]}
            inputProps={{
              placeholder: 'Type here...',
              autoSize: { minRows: 1 },
              maxLength: item?.config?.character_limit,
              autoFocus: autoFocus,
              suffix: (
                <span className="pointer">
                  <Button type="link" size="small">
                    <Microphone
                      size={18}
                      color={activeTheme?.textColor}
                      weight="fill"
                    />
                  </Button>
                </span>
              ),
              activeTheme: activeTheme,
              footerButtons: showFooterButtons && (
                <FooterButtons info={info} isSubmit isSkip />
              ),
            }}
            formItemProps={{ className: 'w-full', required: false }} //using required=false to remove * mark
          />
        );
      case FormFieldType.EMAIL:
        return (
          <FormFieldConversational
            name={info?.name}
            label={info?.label}
            type={FormFieldType.SHORT_TEXT}
            apiErrors={apiErrors}
            rules={[
              ...commonRules,
              formValidation.email,
              formValidation.whitespace,
            ]}
            inputProps={{
              placeholder: 'Type here...',
              autoSize: { minRows: 1 },
              autoFocus: autoFocus,
              maxLength: item?.config?.character_limit,
              suffix: (
                <span className="pointer">
                  <Button type="link" size="small">
                    <Microphone
                      size={18}
                      color={activeTheme?.textColor}
                      weight="fill"
                    />
                  </Button>
                </span>
              ),
              activeTheme: activeTheme,
              footerButtons: showFooterButtons && (
                <FooterButtons info={info} isSubmit isSkip />
              ),
            }}
            formItemProps={{ className: 'w-full', required: false }}
          />
        );
      case FormFieldType.FILE:
        return (
          <FormFieldConversational
            name={info?.name}
            label={info?.label}
            type={FormFieldType.FILE}
            apiErrors={apiErrors}
            rules={[]}
            inputProps={{
              multiple: (item?.config?.max_file_count as number) > 1 ?? false,
              maxCount: item?.config?.max_file_count,
              maxSize: item?.config?.max_file_size,
              accept: getAcceptedFileTypes(item?.config?.file_type),
              activeTheme: activeTheme,
              footerButtons: showFooterButtons && (
                <FooterButtons
                  info={info}
                  isSubmit
                  isSkip
                  isDisabled={fileList?.length === 0 || imageUploading}
                />
              ),
              fileList,
              ...uploadProps,
            }}
            formItemProps={{ className: 'w-full', required: false }}
          />
        );
      case FormFieldType.LONG_TEXT:
        return (
          <FormFieldConversational
            name={info?.name}
            label={info?.label}
            type={FormFieldType.LONG_TEXT}
            apiErrors={apiErrors}
            rules={[...commonRules, formValidation.whitespace]}
            inputProps={{
              placeholder: 'Type here...',
              autoFocus: autoFocus,
              autoSize: { minRows: 1 },
              maxLength: item?.config?.character_limit,
              suffix: (
                <span className="pointer absolute suffix-icon-textarea">
                  <Button type="link" size="small">
                    <Microphone
                      size={18}
                      color={activeTheme?.textColor}
                      weight="fill"
                    />
                  </Button>
                </span>
              ),
              activeTheme: activeTheme,
              footerButtons: showFooterButtons && (
                <FooterButtons info={info} isSubmit isSkip />
              ),
            }}
            formItemProps={{ className: 'w-full', required: false }}
          />
        );
      case FormFieldType.NUMBER:
        return (
          <FormFieldConversational
            name={info?.name}
            label={info?.label}
            type={FormFieldType.NUMBER}
            apiErrors={apiErrors}
            rules={commonRules}
            inputProps={{
              placeholder: 'Type here...',
              autoFocus: autoFocus,
              min: item?.config?.min_number,
              max: item?.config?.max_number,
              controls: false,
              precision: 0,
              suffix: (
                <span className="pointer absolute suffix-icon-textarea">
                  <Button type="link" size="small">
                    <Microphone
                      size={18}
                      color={activeTheme?.textColor}
                      weight="fill"
                    />
                  </Button>
                </span>
              ),
              activeTheme: activeTheme,
              footerButtons: showFooterButtons && (
                <FooterButtons info={info} isSubmit isSkip />
              ),
            }}
            formItemProps={{ className: 'w-full', required: false }}
          />
        );
      case FormFieldType.PHONE_NUMBER:
        return (
          <FormFieldConversational
            name="userResponse"
            label={info?.label}
            type={FormFieldType.NUMBER}
            apiErrors={apiErrors}
            rules={commonRules}
            inputProps={{
              placeholder: 'Type here...',
              controls: false,
              autoFocus: autoFocus,
              suffix: (
                <span className="pointer absolute suffix-icon-textarea">
                  <Button type="link" size="small">
                    <Microphone
                      size={18}
                      color={activeTheme?.textColor}
                      weight="fill"
                    />
                  </Button>
                </span>
              ),
              activeTheme: activeTheme,
              addonBefore: (
                <FormFieldConversational
                  name="countryCode"
                  type={FormFieldType.DROPDOWN}
                  apiErrors={apiErrors}
                  rules={commonRules}
                  inputProps={{
                    placeholder: 'Select',
                    options: DROPDOWN_LIST.countryList,
                    showSearch: true,
                    activeTheme: activeTheme,
                    suffixIcon: (
                      <CaretDown size={18} color={activeTheme?.textColor} />
                    ),
                  }}
                  formItemProps={{ className: 'w-full mb-0', required: false }}
                />
              ),
              footerButtons: showFooterButtons && (
                <FooterButtons info={info} isSubmit isSkip />
              ),
            }}
            formItemProps={{ className: 'w-full', required: false }}
          />
        );
      case FormFieldType.WELCOME_PAGE:
        return (
          <PagePreview
            item={item}
            activeTheme={activeTheme}
            handleCTAClick={handleCTAClick}
            loading={apiLoader}
          />
        );
      case FormFieldType.END_PAGE:
        return (
          <PagePreview
            item={item}
            activeTheme={activeTheme}
            handleCTAClick={handleCTAClick}
          />
        );
      case FormFieldType.RATE:
        return (
          <Space direction="vertical" className="w-full">
            <FormFieldConversational
              name={info?.name}
              label={info?.label}
              type={FormFieldType.RATE}
              apiErrors={apiErrors}
              inputProps={{
                placeholder: 'Type here...',
                className: 'w-full',
                count: item?.config?.max_rating,
                onChange: (value: string) => {
                  form.setFieldValue('userResponse', value);
                  form.submit();
                },
                activeTheme: activeTheme,
                footerButtons: showFooterButtons && (
                  <FooterButtons info={info} isSkip />
                ),
              }}
              formItemProps={{ className: 'w-full', required: false }}
            />
            {/* commenting for future use */}
            {/* {renderItemUI(
              {
                ...item,
                is_required: false,
                answer_type: FormFieldType.SHORT_TEXT,
              },
              {
                name: 'textResponse',
                label: '',
              },
            )} */}
          </Space>
        );
      case FormFieldType.LINEAR_SCALE:
        return (
          <Space direction="vertical" className="w-full">
            <FormFieldConversational
              name={info?.name}
              label={info?.label}
              type={FormFieldType.LINEAR_SCALE}
              apiErrors={apiErrors}
              inputProps={{
                placeholder: 'Type here...',
                min: item?.config?.min_rating,
                max: item?.config?.max_rating,
                minLabel: item?.config?.min_label,
                maxLabel: item?.config?.max_label,
                className: 'w-full',
                marks: getSliderMarks(Number(item?.config?.max_rating)),
                activeTheme: activeTheme,
                footerButtons: showFooterButtons && (
                  <FooterButtons info={info} isSkip isSubmit />
                ),
                onChangeComplete: (value: number) => {
                  form.setFieldValue('userResponse', value);
                },
              }}
              formItemProps={{ className: 'w-full', required: false }}
            />
            {/* commenting for future use */}
            {/* {renderItemUI(
              {
                ...item,
                is_required: false,
                answer_type: FormFieldType.SHORT_TEXT,
              },
              {
                name: 'textResponse',
                label: '',
              },
            )} */}
          </Space>
        );
      case FormFieldType.SELECT:
        return (
          <Space direction="vertical" className="w-full">
            <FormFieldConversational
              name={info?.name}
              label={info?.label}
              type={FormFieldType.SELECT}
              apiErrors={apiErrors}
              inputProps={{
                placeholder: 'Type here...',
                options: item.options ?? [],
                activeTheme: activeTheme,
                onChange: (value: string[]) => {
                  form.setFieldValue('userResponse', value);
                  form.submit();
                },
                footerButtons: showFooterButtons && (
                  <FooterButtons info={info} isSkip />
                ),
              }}
              formItemProps={{ className: 'w-full', required: false }}
            />
            {/* commenting for future use */}
            {/* {renderItemUI(
              {
                ...item,
                is_required: false,
                answer_type: FormFieldType.SHORT_TEXT,
              },
              {
                name: 'textResponse',
                label: '',
              },
            )} */}
          </Space>
        );
      case FormFieldType.DATE:
        return (
          <Space direction="vertical" className="w-full">
            <FormFieldConversational
              name={info?.name}
              label={info?.label}
              type={FormFieldType.DATE}
              apiErrors={apiErrors}
              rules={commonRules}
              inputProps={{
                placeholder: 'Select date',
                onChange: () => {
                  form.submit();
                },
                suffixIcon: (
                  <CalendarBlank
                    size={20}
                    color={activeTheme.textColor}
                    weight="fill"
                  />
                ),
                disabledDate: (date: Dayjs) =>
                  getDisabledDates(date, {
                    allowFuture: item?.config?.allow_future_date,
                    allowPast: item?.config?.allow_past_date,
                  }),
                allowFutureDates: item?.config?.allow_future_date,
                allowPastDates: item?.config?.allow_past_date,
                activeTheme: activeTheme,
                footerButtons: showFooterButtons && (
                  <FooterButtons info={info} isSubmit isSkip />
                ),
              }}
              formItemProps={{ className: 'w-full', required: false }}
            />
            {/* commenting for future use */}
            {/* {renderItemUI(
              {
                ...item,
                is_required: false,
                answer_type: FormFieldType.SHORT_TEXT,
              },
              {
                name: 'textResponse',
                label: '',
              },
            )} */}
          </Space>
        );
      case FormFieldType.TIME:
        return (
          <Space direction="vertical" className="w-full">
            <FormFieldConversational
              name={info?.name}
              label={info?.label}
              type={FormFieldType.TIME}
              apiErrors={apiErrors}
              inputProps={{
                placeholder: 'Select time',
                suffixIcon: (
                  <Clock
                    size={20}
                    color={activeTheme.textColor}
                    weight="fill"
                  />
                ),
                onChange: (val: string, timeString: Dayjs) => {
                  form.setFieldValue('userResponse', timeString);
                },
                activeTheme: activeTheme,
                footerButtons: showFooterButtons && (
                  <FooterButtons info={info} isSubmit isSkip />
                ),
              }}
              formItemProps={{ className: 'w-full', required: false }}
            />
            {/* commenting for future use */}
            {/* {renderItemUI(
              {
                ...item,
                is_required: false,
                answer_type: FormFieldType.SHORT_TEXT,
              },
              {
                name: 'textResponse',
                label: '',
              },
            )} */}
          </Space>
        );
      case FormFieldType.MULTI_SELECT:
        return (
          <Space direction="vertical" className="w-full">
            <FormFieldConversational
              name={info?.name}
              label={info?.label}
              type={FormFieldType.MULTI_SELECT}
              apiErrors={apiErrors}
              inputProps={{
                placeholder: 'Type here...',
                options: item?.options ?? [],
                activeTheme: activeTheme,
                onChange: (value: string[]) => {
                  form.setFieldValue('userResponse', value);
                  setOptionList(value);
                },
                footerButtons: showFooterButtons && (
                  <FooterButtons
                    info={info}
                    isSubmit={!isEmpty(optionList)}
                    isSkip
                    btnText="Ok"
                  />
                ),
              }}
              formItemProps={{ className: 'w-full', required: false }}
            />
            {/* commenting for future use */}
            {/* {renderItemUI(
              {
                ...item,
                is_required: false,
                answer_type: FormFieldType.SHORT_TEXT,
              },
              {
                name: 'textResponse',
                label: '',
              },
            )} */}
          </Space>
        );
      default:
        break;
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          boxShadow: 'unset',
          fontFamily: activeTheme?.fontFamily,
          colorText: activeTheme.textColor,
          colorLink: activeTheme.textColor,
        },
        components: {
          Input: {
            lineWidth: 0,
            borderRadius: 0,
            colorBgContainer: 'transparent',
            colorText: activeTheme.textColor,
            colorError: activeTheme.textColor,
            colorErrorText: activeTheme.textColor,
            boxShadow: 'none',
            colorTextPlaceholder: activeTheme?.textColor,
          },
          Checkbox: {
            paddingContentVertical: 16,
          },
          InputNumber: {
            lineWidth: 0,
            borderRadius: 0,
            colorBgContainer: 'transparent',
            colorText: activeTheme.textColor,
            colorError: activeTheme.textColor,
            colorErrorText: activeTheme.textColor,
            colorTextPlaceholder: activeTheme?.textColor,
            activeBorderColor: 'red',
            boxShadowSecondary: 'none',
            hoverBorderColor: 'red',
          },
          Select: {
            colorText: activeTheme.textColor,
            optionActiveBg: activeTheme.bgPrimary,
            colorBgElevated: activeTheme.bgPrimary,
            optionSelectedColor: activeTheme.bgPrimary,
            optionSelectedBg: activeTheme.textColor,
            lineWidth: 10,
            // colorError: 'var(--neutrals)',
            // colorErrorText: 'var(--red)',
            // colorTextPlaceholder: 'var(--neutrals5)',
            // colorTextPlaceholder: activeTheme.bgPrimary,
            // optionSelectedColor: activeTheme.textColor,
            // optionActiveBg: activeTheme.bgPrimary,
            // optionSelectedBg: activeTheme.bgPrimary,
          },
          DatePicker: {
            colorPrimary: activeTheme.textColor,
            cellActiveWithRangeBg: 'red',
            colorBgContainer: 'transparent',
            hoverBorderColor: activeTheme.textColor,
            activeBorderColor: activeTheme.textColor,
            colorTextDescription: 'var(--neutrals)',
            colorTextPlaceholder: activeTheme.textColor,
            colorError: activeTheme.textColor,
            colorErrorText: activeTheme.textColor,
            borderRadius: 0,
            colorText: activeTheme.textColor,
            colorBorder: activeTheme.textColor,
            colorIcon: activeTheme.textColor,
            colorBgElevated: activeTheme.bgPrimary,
            colorTextLightSolid: activeTheme.bgPrimary,
            colorTextDisabled: `${activeTheme.textColor}7d`,
            cellRangeBorderColor: 'red',
          },
          Form: {
            colorError: 'var(--red)',
            labelFontSize: 32,
            labelColor: activeTheme.textColor,
            verticalLabelPadding: '0 0 32px',
            itemMarginBottom: 32,
          },
          Slider: {
            railBg: activeTheme?.textColor,
            colorBgElevated: activeTheme?.textColor,
            railHoverBg: activeTheme?.textColor,
            trackBg: activeTheme?.textColor,
            colorPrimaryBorderHover: activeTheme?.textColor,
            colorText: activeTheme?.textColor,
            colorTextDescription: activeTheme?.textColor,
            railSize: 1,
            handleSize: 16,
            handleSizeHover: 16,
          },
          Button: {
            colorPrimary: activeTheme.textColor,
            colorPrimaryHover: activeTheme.textColor,
            colorTextLightSolid: activeTheme.bgPrimary,
            defaultColor: activeTheme?.textColor,
            defaultBg: 'transparent',
            defaultBorderColor: activeTheme?.textColor,
            defaultHoverBg: activeTheme?.textColor,
            defaultHoverColor: activeTheme?.bgPrimary,
          },
        },
      }}
    >
      <Form
        form={form}
        onFinish={handleOnFinish}
        autoComplete="off"
        className="conversational-form-renderer form-controls"
        requiredMark={false}
        initialValues={
          item?.answer_type === FormFieldType.PHONE_NUMBER
            ? {
                countryCode: '+91',
              }
            : {}
        }
      >
        <div>
          {renderItemUI(item, {
            name: 'userResponse',
            label: item?.aiResponse || (item?.question_text as string),
          })}
          {branding && <MadeWithZinq mode={themeMode} />}
        </div>
      </Form>
    </ConfigProvider>
  );
}
