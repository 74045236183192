/* eslint-disable no-unused-vars */
import { Dispatch } from 'react';
import {
  Form,
  FormDesign,
  FormTheme,
  FormThemeMode,
  User,
} from '../__generated__/graphql';

type AppStateType = {
  formCreateLoading?: boolean;
  formFetchLoading?: boolean;
  formCreateError?: boolean;
};

export type AppState = {
  currentUser: User;
  authenticated: boolean;
  authToken: string | null;
  currentRole?: string;
  refreshToken?: string | null;
  redirectRoute?: string;
  formSettings?: Form;
  appState?: AppStateType;
  systemThemeMode?: FormThemeMode;
  themeList: FormTheme[];
  activeThemeId: FormDesign['themeId'];
  activeThemeVariationId: FormDesign['themeVariationId'];
  activeMode: FormDesign['themeMode'];
};

export type ThemeIds = {
  activeThemeId?: FormDesign['themeId'];
  activeThemeVariationId?: FormDesign['themeVariationId'];
  activeMode?: FormDesign['themeMode'];
};

export enum AppActionType {
  setCurrentUser = 'SET_CURRENT_USER',
  setAuthenticated = 'SET_AUTHENTICATED',
  setAuthToken = 'SET_TOKEN',
  setCurrentRole = 'SET_CURRENT_ROLE',
  setRefreshToken = 'SET_REFRESH_TOKEN',
  setRedirectRoute = 'SET_REDIRECT_ROUTE',
  setSystemThemeMode = 'SET_SYSTEM_THEME_MODE',
  setAppThemes = 'SET_APP_THEMES',
  setActiveThemeIds = 'SET_ACTIVES_THEME_ID',
  setAppState = 'SET_APP_STATE',
  logout = 'LOGOUT',
  setFormSettings = 'SET_FORM_DATA',
}

export type AppAction =
  | { type: AppActionType.setCurrentUser; data: User }
  | { type: AppActionType.setAuthenticated; data: boolean }
  | { type: AppActionType.setAuthToken; data: string | null }
  | { type: AppActionType.setRefreshToken; data: string | null }
  | { type: AppActionType.setCurrentRole; data: string }
  | { type: AppActionType.setRedirectRoute; data?: string }
  | { type: AppActionType.setAppThemes; data?: FormTheme[] }
  | { type: AppActionType.setActiveThemeIds; data?: ThemeIds }
  | { type: AppActionType.setAppState; data?: AppStateType }
  | { type: AppActionType.setSystemThemeMode; data?: FormThemeMode }
  | { type: AppActionType.logout }
  | { type: AppActionType.setFormSettings; data: Form };

export type AppContextType = {
  state: AppState;
  dispatch: Dispatch<AppAction>;
  getToken: () => string | null;
  isAuthenticated: () => boolean;
  getRefreshToken: () => string | null;
  initializeAuth: (
    authToken: string | null,
    userData: User | null,
    refreshToken?: string | null,
    route?: string,
  ) => void;
  getCurrentUser: () => void;
};
