/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation SignUp($data: SignUpInputData!) {\n  signUp(data: $data) {\n    message\n  }\n}": types.SignUpDocument,
    "\n  mutation LoginUser($data: LoginUserInput!) {\n  loginUser(data: $data) {\n    accessToken\n    user {\n      email\n      phoneNumber\n    }\n    refreshToken\n  }\n}": types.LoginUserDocument,
    "\n  mutation ResetPassword($data: ResetPasswordInput!) {\n  resetPassword(data: $data) {\n    message\n  }\n}": types.ResetPasswordDocument,
    "\n  mutation ForgotPassword($data: ForgotPasswordInput!) {\n  forgotPassword(data: $data) {\n    message\n  }\n}\n": types.ForgotPasswordDocument,
    "\n  mutation Logout {\n  logout {\n    message\n  }\n}": types.LogoutDocument,
    "\n  mutation VerifyEmail($data: VerifyEmailInput) {\n  verifyEmail(data: $data) {\n    message\n  }\n}": types.VerifyEmailDocument,
    "\n  mutation FirebaseLogin($data: FirebaseLoginDataInput) {\n  firebaseLogin(data: $data) {\n    user {\n      email\n      name\n      phoneNumber\n      countryCode\n      profileImage\n      countryCode\n      isNewUser\n      defaultWorkspace {\n        id\n        uuid\n        name\n      }\n    }\n    accessToken\n    refreshToken\n  }\n}": types.FirebaseLoginDocument,
    "\n  mutation RefreshToken($data: RefreshTokenInput!) {\n  refreshToken(data: $data) {\n    accessToken\n    user {\n      email\n      name\n      phoneNumber\n      countryCode\n    }\n  }\n}": types.RefreshTokenDocument,
    "\n mutation UpdateUserProfile($data: UserProfileData!) {\n  updateUserProfile(data: $data) {\n    message\n  }\n}": types.UpdateUserProfileDocument,
    "\n query CurrentUser {\n  currentUser {\n    companyName\n    email\n    phoneNumber\n    name\n    roles\n    profileImage\n    isNewUser\n    countryCode\n    defaultWorkspace {\n      id\n      uuid\n      name\n    }\n  }\n}\n": types.CurrentUserDocument,
    "\n  query Query($data: IsPhoneNumberInUseInput!) {\n  isPhoneNumberInUse(data: $data) {\n    isPhoneNumberInUsed\n  }\n}": types.QueryDocument,
    "\nmutation CreateForm($data: CreateFormInput!) {\n  createForm(data: $data) {\n    data {\n      id\n    }\n  }\n}": types.CreateFormDocument,
    "\nmutation AddFormFields($where: FormUniqueInput!, $data: AddFormFieldInput!) {\n  addFormFields(where: $where, data: $data) {\n    data {\n      overview\n      structure\n    }\n  }\n}": types.AddFormFieldsDocument,
    "\n  mutation GenerateFormStructure($data: FormUniqueInput!) {\n  generateFormStructure(data: $data) {\n    data {\n      id\n      status\n      isUpdated\n      previewKey\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n      }\n      settings {\n        tone\n        generativeQuestion\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n      }\n    }\n  }\n}": types.GenerateFormStructureDocument,
    "\nmutation UpdateFormStructure($where: FormUniqueInput!, $data: UpdateFormStructureInput!) {\n  updateFormStructure(where: $where, data: $data) {\n    data {\n      id\n      status\n      isUpdated\n      previewKey\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n      }\n      settings {\n        tone\n        generativeQuestion\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n      }\n    }\n  }\n}": types.UpdateFormStructureDocument,
    "\nmutation UpdateFormDesign($where: FormUniqueInput!, $data: UpdateFormDesignInput!) {\n  updateFormDesign(where: $where, data: $data) {\n    message\n    data {\n      previewKey\n      status\n      isUpdated\n    }\n  }\n}": types.UpdateFormDesignDocument,
    "\nmutation UpdateFormSetting($where: FormUniqueInput!, $data: UpdateFormSettingInput!) {\n  updateFormSetting(where: $where, data: $data) {\n    message\n    data {\n      previewKey\n      status\n      isUpdated\n    }\n  }\n}": types.UpdateFormSettingDocument,
    "\nmutation StartConversationSubmission($data: StartConversationSubmissionInput!) {\n  startConversationSubmission(data: $data) {\n    data {\n      configs\n      id\n      aiResponse\n      isCompleted\n      submissionId\n    }\n  }\n}": types.StartConversationSubmissionDocument,
    "\n mutation AnswerAndNext($where: TranscriptUniqueInput!, $data: AnswerAndNextData!) {\n  answerAndNext(where: $where, data: $data) {\n    data {\n      aiResponse\n      id\n      configs\n      isCompleted\n      submissionId\n    }\n  }\n}": types.AnswerAndNextDocument,
    "\n mutation PublishForm($where: FormUniqueInput!) {\n  publishForm(where: $where) {\n    message\n    data {\n      previewKey\n      status\n      isUpdated\n    }\n  }\n}": types.PublishFormDocument,
    "\n  mutation UpdateForm($where: FormUniqueInput!, $data: UpdateFormInput!) {\n  updateForm(where: $where, data: $data) {\n    message\n    data {\n      title\n    }\n  }\n}": types.UpdateFormDocument,
    "\n  query GetFormPageImageUploadSignedUrl($data: GetFormPageImageUploadSignedUrlInput!) {\n  getFormPageImageUploadSignedUrl(data: $data) {\n    signedUrl\n    key\n  }\n}": types.GetFormPageImageUploadSignedUrlDocument,
    "\n  query GetSubmissionFilesUploadSignedUrl($data: GetFormFilesUploadSignedUrlInput!) {\n  getSubmissionFilesUploadSignedUrl(data: $data) {\n    signedUrls {\n      key\n      signedUrl\n    }\n  }\n}": types.GetSubmissionFilesUploadSignedUrlDocument,
    "\nquery Form($where: FormUniqueInput!) {\n  form(where: $where) {\n    data {\n      title\n      prompt\n      id\n      previewKey\n      status\n      isUpdated\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n      }\n      settings {\n        tone\n        generativeQuestion\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n      }\n    }\n  }\n}": types.FormDocument,
    "\nquery FormThemes($data: FormUniqueInput!) {\n  formThemes(data: $data) {\n    data {\n      id\n      title\n      configs\n      isUsed\n      type\n      themeVariations {\n        id\n        colorPalette\n        isUsed\n        title\n        type\n      }\n    }\n  }\n}": types.FormThemesDocument,
    "\n  query GetForm($where: GetFormWhereInput!) {\n  getForm(where: $where) {\n    data {\n      title\n      id\n      designs {\n        id\n        theme {\n          configs\n        }\n        themeVariation {\n          colorPalette\n          type\n        }\n        themeMode\n      }\n      structures {\n        structure\n      }\n      settings {\n        generativeQuestion\n      }\n      shares {\n        id\n        formId\n        formType\n        key\n        title\n        embedConfigs\n        ogTitle\n        type\n        ogDescription\n        ogThumbnail\n        ogThumbnailUrl\n      }\n    }\n  }\n}": types.GetFormDocument,
    "\n  query GetFormStructurePagesImageUrl($data: GetFormStructureImageUrlInput!) {\n  getFormStructurePagesImageUrl(data: $data) {\n    imageUrl\n  }\n}": types.GetFormStructurePagesImageUrlDocument,
    "\n  query FormSubmission($where: SubmissionUniqueInput!) {\n  formSubmission(where: $where) {\n    data {\n      isCompleted\n    }\n  }\n}": types.FormSubmissionDocument,
    "\n  query SubmissionTranscripts($filter: ListSubmissionTranscriptsFilter!, $where: SubmissionUniqueInput!, $sort: [ListSubmissionTranscriptsSort!]) {\n  submissionTranscripts(filter: $filter, where: $where, sort: $sort) {\n    data {\n      userResponse {\n        value\n      }\n      configs\n      aiResponse\n    }\n  }\n}": types.SubmissionTranscriptsDocument,
    "\n mutation DeleteAccount {\n  deleteAccount {\n    message\n  }\n}": types.DeleteAccountDocument,
    "\n query GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput!) {\n  getProfileImageUploadSignedUrl(data: $data) {\n    key\n    signedUrl\n  }\n}\n": types.GetProfileImageUploadSignedUrlDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SignUp($data: SignUpInputData!) {\n  signUp(data: $data) {\n    message\n  }\n}"): (typeof documents)["\n  mutation SignUp($data: SignUpInputData!) {\n  signUp(data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation LoginUser($data: LoginUserInput!) {\n  loginUser(data: $data) {\n    accessToken\n    user {\n      email\n      phoneNumber\n    }\n    refreshToken\n  }\n}"): (typeof documents)["\n  mutation LoginUser($data: LoginUserInput!) {\n  loginUser(data: $data) {\n    accessToken\n    user {\n      email\n      phoneNumber\n    }\n    refreshToken\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ResetPassword($data: ResetPasswordInput!) {\n  resetPassword(data: $data) {\n    message\n  }\n}"): (typeof documents)["\n  mutation ResetPassword($data: ResetPasswordInput!) {\n  resetPassword(data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ForgotPassword($data: ForgotPasswordInput!) {\n  forgotPassword(data: $data) {\n    message\n  }\n}\n"): (typeof documents)["\n  mutation ForgotPassword($data: ForgotPasswordInput!) {\n  forgotPassword(data: $data) {\n    message\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Logout {\n  logout {\n    message\n  }\n}"): (typeof documents)["\n  mutation Logout {\n  logout {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation VerifyEmail($data: VerifyEmailInput) {\n  verifyEmail(data: $data) {\n    message\n  }\n}"): (typeof documents)["\n  mutation VerifyEmail($data: VerifyEmailInput) {\n  verifyEmail(data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation FirebaseLogin($data: FirebaseLoginDataInput) {\n  firebaseLogin(data: $data) {\n    user {\n      email\n      name\n      phoneNumber\n      countryCode\n      profileImage\n      countryCode\n      isNewUser\n      defaultWorkspace {\n        id\n        uuid\n        name\n      }\n    }\n    accessToken\n    refreshToken\n  }\n}"): (typeof documents)["\n  mutation FirebaseLogin($data: FirebaseLoginDataInput) {\n  firebaseLogin(data: $data) {\n    user {\n      email\n      name\n      phoneNumber\n      countryCode\n      profileImage\n      countryCode\n      isNewUser\n      defaultWorkspace {\n        id\n        uuid\n        name\n      }\n    }\n    accessToken\n    refreshToken\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RefreshToken($data: RefreshTokenInput!) {\n  refreshToken(data: $data) {\n    accessToken\n    user {\n      email\n      name\n      phoneNumber\n      countryCode\n    }\n  }\n}"): (typeof documents)["\n  mutation RefreshToken($data: RefreshTokenInput!) {\n  refreshToken(data: $data) {\n    accessToken\n    user {\n      email\n      name\n      phoneNumber\n      countryCode\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation UpdateUserProfile($data: UserProfileData!) {\n  updateUserProfile(data: $data) {\n    message\n  }\n}"): (typeof documents)["\n mutation UpdateUserProfile($data: UserProfileData!) {\n  updateUserProfile(data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n query CurrentUser {\n  currentUser {\n    companyName\n    email\n    phoneNumber\n    name\n    roles\n    profileImage\n    isNewUser\n    countryCode\n    defaultWorkspace {\n      id\n      uuid\n      name\n    }\n  }\n}\n"): (typeof documents)["\n query CurrentUser {\n  currentUser {\n    companyName\n    email\n    phoneNumber\n    name\n    roles\n    profileImage\n    isNewUser\n    countryCode\n    defaultWorkspace {\n      id\n      uuid\n      name\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Query($data: IsPhoneNumberInUseInput!) {\n  isPhoneNumberInUse(data: $data) {\n    isPhoneNumberInUsed\n  }\n}"): (typeof documents)["\n  query Query($data: IsPhoneNumberInUseInput!) {\n  isPhoneNumberInUse(data: $data) {\n    isPhoneNumberInUsed\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation CreateForm($data: CreateFormInput!) {\n  createForm(data: $data) {\n    data {\n      id\n    }\n  }\n}"): (typeof documents)["\nmutation CreateForm($data: CreateFormInput!) {\n  createForm(data: $data) {\n    data {\n      id\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation AddFormFields($where: FormUniqueInput!, $data: AddFormFieldInput!) {\n  addFormFields(where: $where, data: $data) {\n    data {\n      overview\n      structure\n    }\n  }\n}"): (typeof documents)["\nmutation AddFormFields($where: FormUniqueInput!, $data: AddFormFieldInput!) {\n  addFormFields(where: $where, data: $data) {\n    data {\n      overview\n      structure\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GenerateFormStructure($data: FormUniqueInput!) {\n  generateFormStructure(data: $data) {\n    data {\n      id\n      status\n      isUpdated\n      previewKey\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n      }\n      settings {\n        tone\n        generativeQuestion\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n      }\n    }\n  }\n}"): (typeof documents)["\n  mutation GenerateFormStructure($data: FormUniqueInput!) {\n  generateFormStructure(data: $data) {\n    data {\n      id\n      status\n      isUpdated\n      previewKey\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n      }\n      settings {\n        tone\n        generativeQuestion\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdateFormStructure($where: FormUniqueInput!, $data: UpdateFormStructureInput!) {\n  updateFormStructure(where: $where, data: $data) {\n    data {\n      id\n      status\n      isUpdated\n      previewKey\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n      }\n      settings {\n        tone\n        generativeQuestion\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n      }\n    }\n  }\n}"): (typeof documents)["\nmutation UpdateFormStructure($where: FormUniqueInput!, $data: UpdateFormStructureInput!) {\n  updateFormStructure(where: $where, data: $data) {\n    data {\n      id\n      status\n      isUpdated\n      previewKey\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n      }\n      settings {\n        tone\n        generativeQuestion\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdateFormDesign($where: FormUniqueInput!, $data: UpdateFormDesignInput!) {\n  updateFormDesign(where: $where, data: $data) {\n    message\n    data {\n      previewKey\n      status\n      isUpdated\n    }\n  }\n}"): (typeof documents)["\nmutation UpdateFormDesign($where: FormUniqueInput!, $data: UpdateFormDesignInput!) {\n  updateFormDesign(where: $where, data: $data) {\n    message\n    data {\n      previewKey\n      status\n      isUpdated\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdateFormSetting($where: FormUniqueInput!, $data: UpdateFormSettingInput!) {\n  updateFormSetting(where: $where, data: $data) {\n    message\n    data {\n      previewKey\n      status\n      isUpdated\n    }\n  }\n}"): (typeof documents)["\nmutation UpdateFormSetting($where: FormUniqueInput!, $data: UpdateFormSettingInput!) {\n  updateFormSetting(where: $where, data: $data) {\n    message\n    data {\n      previewKey\n      status\n      isUpdated\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation StartConversationSubmission($data: StartConversationSubmissionInput!) {\n  startConversationSubmission(data: $data) {\n    data {\n      configs\n      id\n      aiResponse\n      isCompleted\n      submissionId\n    }\n  }\n}"): (typeof documents)["\nmutation StartConversationSubmission($data: StartConversationSubmissionInput!) {\n  startConversationSubmission(data: $data) {\n    data {\n      configs\n      id\n      aiResponse\n      isCompleted\n      submissionId\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation AnswerAndNext($where: TranscriptUniqueInput!, $data: AnswerAndNextData!) {\n  answerAndNext(where: $where, data: $data) {\n    data {\n      aiResponse\n      id\n      configs\n      isCompleted\n      submissionId\n    }\n  }\n}"): (typeof documents)["\n mutation AnswerAndNext($where: TranscriptUniqueInput!, $data: AnswerAndNextData!) {\n  answerAndNext(where: $where, data: $data) {\n    data {\n      aiResponse\n      id\n      configs\n      isCompleted\n      submissionId\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation PublishForm($where: FormUniqueInput!) {\n  publishForm(where: $where) {\n    message\n    data {\n      previewKey\n      status\n      isUpdated\n    }\n  }\n}"): (typeof documents)["\n mutation PublishForm($where: FormUniqueInput!) {\n  publishForm(where: $where) {\n    message\n    data {\n      previewKey\n      status\n      isUpdated\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateForm($where: FormUniqueInput!, $data: UpdateFormInput!) {\n  updateForm(where: $where, data: $data) {\n    message\n    data {\n      title\n    }\n  }\n}"): (typeof documents)["\n  mutation UpdateForm($where: FormUniqueInput!, $data: UpdateFormInput!) {\n  updateForm(where: $where, data: $data) {\n    message\n    data {\n      title\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFormPageImageUploadSignedUrl($data: GetFormPageImageUploadSignedUrlInput!) {\n  getFormPageImageUploadSignedUrl(data: $data) {\n    signedUrl\n    key\n  }\n}"): (typeof documents)["\n  query GetFormPageImageUploadSignedUrl($data: GetFormPageImageUploadSignedUrlInput!) {\n  getFormPageImageUploadSignedUrl(data: $data) {\n    signedUrl\n    key\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSubmissionFilesUploadSignedUrl($data: GetFormFilesUploadSignedUrlInput!) {\n  getSubmissionFilesUploadSignedUrl(data: $data) {\n    signedUrls {\n      key\n      signedUrl\n    }\n  }\n}"): (typeof documents)["\n  query GetSubmissionFilesUploadSignedUrl($data: GetFormFilesUploadSignedUrlInput!) {\n  getSubmissionFilesUploadSignedUrl(data: $data) {\n    signedUrls {\n      key\n      signedUrl\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery Form($where: FormUniqueInput!) {\n  form(where: $where) {\n    data {\n      title\n      prompt\n      id\n      previewKey\n      status\n      isUpdated\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n      }\n      settings {\n        tone\n        generativeQuestion\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n      }\n    }\n  }\n}"): (typeof documents)["\nquery Form($where: FormUniqueInput!) {\n  form(where: $where) {\n    data {\n      title\n      prompt\n      id\n      previewKey\n      status\n      isUpdated\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n      }\n      settings {\n        tone\n        generativeQuestion\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery FormThemes($data: FormUniqueInput!) {\n  formThemes(data: $data) {\n    data {\n      id\n      title\n      configs\n      isUsed\n      type\n      themeVariations {\n        id\n        colorPalette\n        isUsed\n        title\n        type\n      }\n    }\n  }\n}"): (typeof documents)["\nquery FormThemes($data: FormUniqueInput!) {\n  formThemes(data: $data) {\n    data {\n      id\n      title\n      configs\n      isUsed\n      type\n      themeVariations {\n        id\n        colorPalette\n        isUsed\n        title\n        type\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetForm($where: GetFormWhereInput!) {\n  getForm(where: $where) {\n    data {\n      title\n      id\n      designs {\n        id\n        theme {\n          configs\n        }\n        themeVariation {\n          colorPalette\n          type\n        }\n        themeMode\n      }\n      structures {\n        structure\n      }\n      settings {\n        generativeQuestion\n      }\n      shares {\n        id\n        formId\n        formType\n        key\n        title\n        embedConfigs\n        ogTitle\n        type\n        ogDescription\n        ogThumbnail\n        ogThumbnailUrl\n      }\n    }\n  }\n}"): (typeof documents)["\n  query GetForm($where: GetFormWhereInput!) {\n  getForm(where: $where) {\n    data {\n      title\n      id\n      designs {\n        id\n        theme {\n          configs\n        }\n        themeVariation {\n          colorPalette\n          type\n        }\n        themeMode\n      }\n      structures {\n        structure\n      }\n      settings {\n        generativeQuestion\n      }\n      shares {\n        id\n        formId\n        formType\n        key\n        title\n        embedConfigs\n        ogTitle\n        type\n        ogDescription\n        ogThumbnail\n        ogThumbnailUrl\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFormStructurePagesImageUrl($data: GetFormStructureImageUrlInput!) {\n  getFormStructurePagesImageUrl(data: $data) {\n    imageUrl\n  }\n}"): (typeof documents)["\n  query GetFormStructurePagesImageUrl($data: GetFormStructureImageUrlInput!) {\n  getFormStructurePagesImageUrl(data: $data) {\n    imageUrl\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query FormSubmission($where: SubmissionUniqueInput!) {\n  formSubmission(where: $where) {\n    data {\n      isCompleted\n    }\n  }\n}"): (typeof documents)["\n  query FormSubmission($where: SubmissionUniqueInput!) {\n  formSubmission(where: $where) {\n    data {\n      isCompleted\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SubmissionTranscripts($filter: ListSubmissionTranscriptsFilter!, $where: SubmissionUniqueInput!, $sort: [ListSubmissionTranscriptsSort!]) {\n  submissionTranscripts(filter: $filter, where: $where, sort: $sort) {\n    data {\n      userResponse {\n        value\n      }\n      configs\n      aiResponse\n    }\n  }\n}"): (typeof documents)["\n  query SubmissionTranscripts($filter: ListSubmissionTranscriptsFilter!, $where: SubmissionUniqueInput!, $sort: [ListSubmissionTranscriptsSort!]) {\n  submissionTranscripts(filter: $filter, where: $where, sort: $sort) {\n    data {\n      userResponse {\n        value\n      }\n      configs\n      aiResponse\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation DeleteAccount {\n  deleteAccount {\n    message\n  }\n}"): (typeof documents)["\n mutation DeleteAccount {\n  deleteAccount {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n query GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput!) {\n  getProfileImageUploadSignedUrl(data: $data) {\n    key\n    signedUrl\n  }\n}\n"): (typeof documents)["\n query GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput!) {\n  getProfileImageUploadSignedUrl(data: $data) {\n    key\n    signedUrl\n  }\n}\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;