import { gql } from '../../../__generated__';

// fetch form editor settings
export const GET_FORM = gql(`
query Form($where: FormUniqueInput!) {
  form(where: $where) {
    data {
      title
      prompt
      id
      previewKey
      status
      isUpdated
      structures {
        overview
        structure
      }
      designs {
        themeId
        themeMode
        themeVariationId
      }
      settings {
        tone
        generativeQuestion
        finalPreview
        notifyOn
        notifyAtDay
        notifyAtSubmission
      }
    }
  }
}`);

export const GET_FORM_THEMES = gql(`
query FormThemes($data: FormUniqueInput!) {
  formThemes(data: $data) {
    data {
      id
      title
      configs
      isUsed
      type
      themeVariations {
        id
        colorPalette
        isUsed
        title
        type
      }
    }
  }
}`);

export const GET_FORM_DETAILS = gql(`
  query GetForm($where: GetFormWhereInput!) {
  getForm(where: $where) {
    data {
      title
      id
      designs {
        id
        theme {
          configs
        }
        themeVariation {
          colorPalette
          type
        }
        themeMode
      }
      structures {
        structure
      }
      settings {
        generativeQuestion
      }
      shares {
        id
        formId
        formType
        key
        title
        embedConfigs
        ogTitle
        type
        ogDescription
        ogThumbnail
        ogThumbnailUrl
      }
    }
  }
}`);

export const GET_PAGE_BG_URL = gql(`
  query GetFormStructurePagesImageUrl($data: GetFormStructureImageUrlInput!) {
  getFormStructurePagesImageUrl(data: $data) {
    imageUrl
  }
}`);

export const GET_FORM_SUBMISSION = gql(`
  query FormSubmission($where: SubmissionUniqueInput!) {
  formSubmission(where: $where) {
    data {
      isCompleted
    }
  }
}`);

export const GET_FORM_TRANSCRIPTS = gql(`
  query SubmissionTranscripts($filter: ListSubmissionTranscriptsFilter!, $where: SubmissionUniqueInput!, $sort: [ListSubmissionTranscriptsSort!]) {
  submissionTranscripts(filter: $filter, where: $where, sort: $sort) {
    data {
      userResponse {
        value
      }
      configs
      aiResponse
    }
  }
}`);
