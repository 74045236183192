import { CloseCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Result, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { AppContext } from './AppContext';
import PublicRoute from './PublicRoute';
import {
  ERROR_PAGE_SUBTITLE,
  ERROR_PAGE_TITLE,
  ROUTES,
} from './common/constants';
import Error404 from './components/common/Error404';
import ErrorPage from './components/common/ErrorPage';
import LoaderComponent from './components/common/LoaderComponent';
import MaintenancePage from './components/common/MaintenancePage';
import FormViewLayout from './components/layouts/FormViewLayout';
import ScreenLayout from './components/layouts/ScreenLayout';
import history from './historyData';
import { GET_CURRENT_USER } from './modules/auth/graphql/queries';
import ConversationalView from './modules/form/view/ConversationalView';
import { AppContextType } from './types/appContext.type';

type FallbackProps = {
  error: Error | null;
  componentStack: string | null;
};

const { Paragraph } = Typography;

const MyFallbackComponent = ({ error, componentStack }: FallbackProps) => (
  <Result
    status="error"
    title={ERROR_PAGE_TITLE}
    subTitle={ERROR_PAGE_SUBTITLE}
  >
    <div className="desc">
      <Paragraph>
        <Typography.Title level={4}> Error:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {error?.message?.toString()}
      </Paragraph>
      <Paragraph>
        <Typography.Title level={4}> Stacktrace:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {componentStack}
      </Paragraph>
    </div>
  </Result>
);

const RoutesCollection = () => {
  const FORM_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PublicRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <FormViewLayout />,
          children: [
            { path: ROUTES.FORM, element: <ConversationalView /> },
            {
              path: ROUTES.MAIN,
              element: (
                <ScreenLayout>
                  <Error404 />
                </ScreenLayout>
              ),
            },
          ],
        },
      ],
    },
  ];

  const OTHER_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PublicRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <ScreenLayout />,
          children: [
            {
              path: ROUTES.ERROR,
              element: <ErrorPage />,
            },
            {
              path: '*',
              element: <Error404 />,
            },
          ],
        },
      ],
    },
  ];

  const element = useRoutes([...FORM_MODULES, ...OTHER_MODULES]);
  return element;
};

const RoutesWrapper = () => {
  const {
    initializeAuth,
    getToken,
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;
  const [loading, setLoading] = useState(true);
  const path = history?.location?.pathname;
  const idToken = getToken();

  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      initializeAuth(idToken, res?.currentUser ?? null);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
      // history?.push(ROUTES.LOGOUT);
    },
  });

  useEffect(() => {
    if (idToken && isEmpty(currentUser) && path !== ROUTES?.AUTHENTICATION) {
      getCurrentUser();
    } else {
      setLoading(false);
    }

    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  if (loading) return <LoaderComponent />;
  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router>
        {maintenance === 'true' ? <MaintenancePage /> : <RoutesCollection />}
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default RoutesWrapper;
