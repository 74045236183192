import { isArray } from 'lodash';
import { useState } from 'react';
import {
  AnswerAndNextMutation,
  Form,
  FormThemeMode,
  SubmissionTranscript,
} from '../../../__generated__/graphql';
import useQueryParams from '../../../hooks/useQueryParams';
import { Palette } from '../../form-builder/model/form/form.types';
import { ANSWER_AND_NEXT } from '../graphql/mutations';
import { useForm } from '../hooks/useForm';
import ConversationalUIPreview from './ConversationalUIPreview';

type FormResponseType = {
  input?: SubmissionTranscript;
  onResponse: (data: SubmissionTranscript) => void;
  formDetails: Form;
  activeTheme: Palette;
  handleCTAClick: () => void;
  handleFormCompletion: () => void;
  formSubmission?: SubmissionTranscript[];
  themeMode: FormThemeMode;
  apiLoader?: boolean;
};

export default function FormResponse({
  input,
  onResponse,
  formDetails,
  formSubmission = [],
  activeTheme,
  themeMode,
  apiLoader,
  handleCTAClick,
  handleFormCompletion,
}: FormResponseType) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [transcripts, setTranscripts] = useState<any>([]);
  const { updateQueryParams, getQueryParam } = useQueryParams();
  const formKey = getQueryParam('formKey') as string;
  const formPreviewKey = getQueryParam('formPreviewKey') as string;
  const [formState, setFormState] = useState('');
  const { form, loading, apiErrors, handleSubmit } = useForm(ANSWER_AND_NEXT, {
    onCompleted: (res: AnswerAndNextMutation) => {
      const response = res.answerAndNext?.data;
      if (response) {
        if (response?.isCompleted) {
          handleFormCompletion();
        } else {
          onResponse(response);
          setTranscripts([
            ...transcripts,
            {
              type: input?.configs?.answer_type,
              question: input?.aiResponse,
              value: form.getFieldValue('userResponse'),
            },
          ]);
          form.resetFields();
        }
      }
    },
    onError: () => {},
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnFinish = async (value: any): Promise<boolean> => {
    const payload = isArray(value?.userResponse)
      ? value?.userResponse
      : `${value?.countryCode || ''}${value?.userResponse || ''}`;
    form.setFieldValue('userResponse', payload || 'SKIP');
    await handleSubmit({
      where: {
        transcriptId: input?.id,
      },
      data: {
        submissionDuration: 2, // manage dynamically
        userResponse: payload ? payload : 'SKIP',
        formKey: formKey,
        previewKey: formPreviewKey,
      },
    });
    form.resetFields();
    return true;
  };

  return (
    <section className="w-full">
      <div className="conversational-form-preview">
        {/* {transcripts && JSON.stringify(transcripts)} */}
        {/* <ConversationalUIPreview
            item={{
              question_text: 'test',
              answer_type: FormFieldType.RATE,
              answer_store_key: '',
            }}
            activeTheme={activeTheme}
            onFinish={() => {}}
            handleCTAClick={() => {}}
            showFooterButtons={false}
          /> */}
        {input?.configs && (
          <ConversationalUIPreview
            item={{
              ...input?.configs,
              question_text: input?.aiResponse,
            }}
            autoFocus
            themeMode={themeMode}
            isUpload
            branding={true}
            onFinish={async (value) => {
              // eslint-disable-next-line no-console
              // console.log(value);
              return await handleOnFinish(value);
            }}
            apiLoader={apiLoader || loading}
            handleCTAClick={handleCTAClick}
            activeTheme={activeTheme}
            showFooterButtons
          />
        )}
      </div>
    </section>
  );
}
