import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { App, ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import '../src/styles/main.scss';
import { AppContextProvider } from './AppContext';
import RoutesWrapper from './RoutesWrapper';
import client from './apollo';
import AppVersion from './components/common/AppVersion';
import ConnectionMode from './components/common/ConnectionMode';
import CookieConsent from './components/common/CookieConsent';
import DetectSystemThemeMode from './components/common/DetectSystemThemeMode';
import AppContextHolder from './components/context/AppContextHolder';
import themeConfig from './styles/theme/themeConfig';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
});

// use this variable from envs so that we can active connection mode feature in app.
const connectionMode = process.env.REACT_APP_CONNECTION_MODE_ENABLE;

// use this variable from envs so that we can active cookie consent mode feature in app.
const cookieMode = process.env.REACT_APP_COOKIE_CONSENT_ENABLE;

// eslint-disable-next-line no-undef
const root = ReactDOM?.createRoot(
  document?.getElementById('root') as HTMLElement,
);

root?.render(
  <HelmetProvider>
    <ApolloProvider client={client}>
      <AppContextProvider>
        <App>
          {/* Adding toast-container to render toast messages [ant v5.19.4] */}
          <AppContextHolder />
          <ConfigProvider theme={themeConfig} prefixCls="zinq">
            {/* Adding connection mode to notify user when they are offline/online */}
            {connectionMode === 'true' && <ConnectionMode />}
            {/* Adding cook
          ie consent component to inform users about cookies we use */}
            {cookieMode === 'true' && <CookieConsent />}
            <RoutesWrapper />
          </ConfigProvider>
          <AppVersion />
          <DetectSystemThemeMode />
        </App>
      </AppContextProvider>
    </ApolloProvider>
  </HelmetProvider>,
);
