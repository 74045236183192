import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ConfigProvider } from 'antd';
import { isEmpty, lowerCase } from 'lodash';
import { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  Form,
  FormSetting,
  FormShare,
  FormSubmissionType,
  FormThemeMode,
  FormVisualType,
  SubmissionTranscript,
} from '../../../__generated__/graphql';
import { ROUTES, getThemeBackground } from '../../../common/constants';
import MetaHeader from '../../../components/common/MetaHeader';
import useQueryParams from '../../../hooks/useQueryParams';
import useRouter from '../../../hooks/useRouter';
import { AppContextType } from '../../../types/appContext.type';
import { FormFieldType } from '../../auth/auth.types';
import {
  EmbedTypes,
  FormSchemaType,
  FormThemeType,
  PageLayoutType,
  Palette,
} from '../../form-builder/model/form/form.types';
import FormBrandingPage from '../editor/pages/FormBrandingPage';
import { START_CONVERSATIONS_SUBMISSION } from '../graphql/mutations';
import { GET_FORM_DETAILS, GET_FORM_SUBMISSION } from '../graphql/queries';
import FormResponse from './FormResponse';

export default function ConversationalView() {
  const [responseData, setResponseData] = useState<SubmissionTranscript>();

  const { updateQueryParams, getQueryParam } = useQueryParams();
  const formKey = getQueryParam('formKey') as string;
  const formPreviewKey = getQueryParam('formPreviewKey') as string;
  const previewType = getQueryParam('previewType') as string;
  const [formDetails, setFormDetails] = useState<Form>();
  const [formShareDetails, setFormShareDetails] = useState<FormShare>();
  const [formSetting, setFormSetting] = useState<FormSetting>();
  const [formSubmission, setFormSubmission] = useState<
    SubmissionTranscript[] | null
  >([]);
  const [showBrandPage, setShowBrandPage] = useState<boolean>(false);

  const {
    state: { systemThemeMode },
  } = useContext(AppContext) as AppContextType;

  const [startConversationMutate, { loading }] = useMutation(
    START_CONVERSATIONS_SUBMISSION,
    {
      onCompleted: (res) => {
        if (res.startConversationSubmission?.data) {
          // eslint-disable-next-line no-console
          // console.log(res);
          const response = res.startConversationSubmission?.data;
          setResponseData(response);
          if (response.submissionId) {
            localStorage.setItem(
              formPreviewKey || formKey,
              response.submissionId,
            );
          }
        }
      },
      onError() {},
    },
  );

  const { navigate } = useRouter();
  const [getFormSubmission] = useLazyQuery(GET_FORM_SUBMISSION);

  const handleFormCompletion = (data?: Form) => {
    const endPage = (data || formDetails)?.structures?.[0]?.structure?.filter(
      (item) => item.answer_type === FormFieldType.END_PAGE,
    )?.[0];

    if (!isEmpty(endPage) && endPage?.is_display) {
      setResponseData({
        configs: { ...endPage },
      });
    } else {
      setShowBrandPage(true);
    }
  };

  const { loading: formDetailsLoading } = useQuery(GET_FORM_DETAILS, {
    variables: {
      where: {
        formKey,
        formPreviewKey,
      },
    },
    onCompleted: (formResponse) => {
      if (formResponse.getForm?.data) {
        setFormDetails(formResponse.getForm?.data);
      }
      const shareList = formResponse.getForm?.data?.shares;
      const settingList = formResponse.getForm?.data?.settings;

      if (!isEmpty(shareList)) {
        setFormShareDetails(shareList?.[0] as FormShare);
      }

      if (!isEmpty(settingList)) {
        setFormSetting(settingList?.[0] as FormSetting);
      }

      const structure = formResponse.getForm?.data?.structures?.[0]?.structure;

      const welcomePage = (structure as FormSchemaType[])?.filter(
        (item) => item.answer_type === FormFieldType.WELCOME_PAGE,
      )?.[0];

      const submissionId = localStorage.getItem(formPreviewKey || formKey);
      if (submissionId) {
        getFormSubmission({
          variables: {
            where: {
              submissionId,
            },
          },
          onCompleted: (res) => {
            const response = res.formSubmission?.data;
            if (!response?.isCompleted) {
              startConversationMutate({
                variables: {
                  data: {
                    formKey,
                    formPreviewKey,
                    formType: (previewType ||
                      shareList?.[0]?.type) as FormSubmissionType,
                    metadata: {
                      generativeQuestion:
                        !!settingList?.[0]?.generativeQuestion,
                      formVisualType: previewType
                        ? FormVisualType.Link
                        : shareList?.[0]?.embedConfigs?.type,
                    },
                  },
                },
              });
              // getFormTranscripts({
              //   variables: {
              //     where: {
              //       submissionId,
              //     },
              //     sort: {
              //       sortOn: ListSubmissionTranscriptsSortOnField.CreatedAt,
              //       sortBy: SortOrder.Desc,
              //     },
              //     filter: {
              //       limit: 5,
              //       skip: 0,
              //     },
              //   },
              //   onCompleted: (res) => {
              //     setFormSubmission(
              //       res.submissionTranscripts?.data as SubmissionTranscript[],
              //     );
              //   },
              // });
            } else {
              if (formResponse.getForm?.data) {
                handleFormCompletion(formResponse.getForm?.data);
              }
            }
          },
        });
      } else {
        const isIframe =
          shareList?.[0]?.embedConfigs?.type === EmbedTypes.IFRAME;
        const shouldShowWelcomePage =
          !isEmpty(welcomePage) &&
          ((isIframe && shareList?.[0]?.embedConfigs?.isWelcomePage) ||
            (!isIframe && welcomePage?.is_display));

        if (shouldShowWelcomePage) {
          setResponseData({
            configs: { ...welcomePage },
          });
        } else {
          startConversationMutate({
            variables: {
              data: {
                formKey,
                formPreviewKey,
                formType: (previewType ||
                  shareList?.[0]?.type) as FormSubmissionType,
                metadata: {
                  generativeQuestion: !!settingList?.[0]?.generativeQuestion,
                  formVisualType: previewType
                    ? FormVisualType.Link
                    : shareList?.[0]?.embedConfigs?.type,
                },
              },
            },
          });
        }
      }
    },
    onError: () => {
      navigate(ROUTES.NOT_FOUND);
    },
  });

  // const [getFormTranscripts] = useLazyQuery(GET_FORM_TRANSCRIPTS);

  const handleOnResponse = (data: SubmissionTranscript) => {
    if (data?.submissionId) {
      localStorage.setItem(formPreviewKey || formKey, data.submissionId);
      // getFormTranscripts({
      //   variables: {
      //     where: {
      //       submissionId: data?.submissionId,
      //     },
      //     sort: {
      //       sortOn: ListSubmissionTranscriptsSortOnField.CreatedAt,
      //       sortBy: SortOrder.Desc,
      //     },
      //     filter: {
      //       limit: 5,
      //       skip: 0,
      //     },
      //   },
      //   onCompleted: (res) => {
      //     setFormSubmission(
      //       res.submissionTranscripts?.data as SubmissionTranscript[],
      //     );
      //   },
      // });
    }
    setResponseData({ ...responseData, ...data });
  };

  // fetch form submission - prefilled
  // useEffect(() => {
  //   const submissionId = localStorage.getItem(formPreviewKey || formKey);
  //   if (submissionId) {
  //     getFormSubmission({
  //       variables: {
  //         where: {
  //           submissionId,
  //         },
  //       },
  //       onCompleted: (res) => {
  //         const response = res.formSubmission?.data;
  //         // eslint-disable-next-line no-console
  //         console.log(response);
  //         if (!response?.isCompleted) {
  //           // getFormTranscripts({
  //           //   variables: {
  //           //     where: {
  //           //       submissionId,
  //           //     },
  //           //     sort: {
  //           //       sortOn: ListSubmissionTranscriptsSortOnField.CreatedAt,
  //           //       sortBy: SortOrder.Desc,
  //           //     },
  //           //     filter: {
  //           //       limit: 5,
  //           //       skip: 0,
  //           //     },
  //           //   },
  //           //   onCompleted: (res) => {
  //           //     setFormSubmission(
  //           //       res.submissionTranscripts?.data as SubmissionTranscript[],
  //           //     );
  //           //   },
  //           // });
  //         } else {
  //           handleFormCompletion();
  //         }
  //       },
  //     });
  //   }
  // }, []);

  const mode =
    formDetails?.designs?.[0]?.themeMode === FormThemeMode.Auto
      ? systemThemeMode
      : formDetails?.designs?.[0]?.themeMode;

  const activeTheme: Palette =
    formDetails?.designs?.[0]?.themeVariation?.colorPalette[
      lowerCase(mode as FormThemeMode)
    ];

  return (
    <section className="h-screen">
      <MetaHeader
        title={formShareDetails?.ogTitle || ''}
        description={formShareDetails?.ogDescription || ''}
        imgUrl={formShareDetails?.ogThumbnailUrl || ''}
      />
      <div
        className="conversational-view-wrapper form-controls"
        style={
          (responseData?.configs?.answer_type === FormFieldType.WELCOME_PAGE ||
            responseData?.configs?.answer_type === FormFieldType.END_PAGE) &&
          responseData?.configs?.page_layout === PageLayoutType.PAGE_FILLED &&
          responseData?.configs?.bg_image_url
            ? {
                background: `url("${responseData?.configs?.bg_image_url}")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                boxShadow: 'rgba(0, 0, 0, 0.50) 0px 0px 0px 2000px inset',
              }
            : getThemeBackground(
                formDetails?.designs?.[0]?.themeVariation
                  ?.type as FormThemeType,
                activeTheme,
              )
        }
      >
        <div className="container">
          <div className="form-response">
            <ConfigProvider
              theme={{
                components: {
                  Input: {
                    lineWidth: 0,
                    borderRadius: 0,
                    colorBgContainer: 'transparent',
                    colorText: 'var(--neutrals)',
                    hoverBorderColor: 'transparent',
                    activeBorderColor: 'transparent',
                    colorError: 'var(--neutrals)',
                    colorErrorText: 'var(--neutrals)',
                    colorTextPlaceholder: 'var(--neutrals5)',
                  },
                  Form: {
                    colorError: 'var(--neutrals)',
                  },
                },
              }}
            >
              {showBrandPage && mode && activeTheme ? (
                <FormBrandingPage
                  themeMode={mode}
                  activeTheme={activeTheme}
                  type={
                    formDetails?.designs?.[0]?.themeVariation
                      ?.type as FormThemeType
                  }
                />
              ) : (
                responseData &&
                formDetails && (
                  <FormResponse
                    input={responseData}
                    onResponse={(data) => {
                      handleOnResponse(data);
                    }}
                    apiLoader={loading}
                    activeTheme={activeTheme}
                    themeMode={mode as FormThemeMode}
                    formDetails={formDetails}
                    handleCTAClick={() => {
                      startConversationMutate({
                        variables: {
                          data: {
                            formKey,
                            formPreviewKey,
                            formType: (previewType ||
                              formShareDetails?.type) as FormSubmissionType,
                            metadata: {
                              generativeQuestion:
                                !!formSetting?.generativeQuestion,
                              formVisualType: previewType
                                ? FormVisualType.Link
                                : formShareDetails?.embedConfigs?.type,
                            },
                          },
                        },
                      });
                    }}
                    handleFormCompletion={() => {
                      handleFormCompletion();
                    }}
                    formSubmission={formSubmission || []}
                  />
                )
              )}
              {}
            </ConfigProvider>
          </div>
        </div>
      </div>
    </section>
  );
}
